import React from 'react';

// const DataSource ={
//   NetCorePrd: "https://localhost:7152",
//   GolangPrd: "http://localhost:9001"
// };

console.log(process.env.REACT_APP_BACKEND_URL)

const DataSource ={
  NetCorePrd: process.env.REACT_APP_BACKEND_URL,
  GolangPrd: "http://localhost:9001"
};

export default DataSource;