// config.js
const config = {
    PagerfetchUrl: 'negotiate/pager',
    Negotiate : 'negotiate',
    Auth : 'auth/login',
    fetchconfig : {
        appid : "medhio",
        org:"ew"
    }
  };
  
  export default config;
  