import React from 'react'

const BreadCrum = ({ breadcrum  }) => {
  return (

    <div class="row">
    <div class="col-12">
         <div class="page-title-box page-title-box-alt">
            <h4 id="breadmodule" class="page-title">{breadcrum.view}</h4>
            <div class="page-title-right">
                <ol class="breadcrumb m-0">
                <li id="breadmenu" class="breadcrumb-item">{breadcrum.menu}</li>

             
                 {breadcrum.module &&  ( <li id="breadmode" class="breadcrumb-item">{breadcrum.module}</li>) }
                 {breadcrum.mode &&  ( <li id="breadmode" class="breadcrumb-item">{breadcrum.mode}</li>) }
                 {breadcrum.action &&  ( <li id="breadaction" class="breadcrumb-item active">{breadcrum.action}</li>)}
                </ol>
            </div>
        </div>
    </div>
</div>
    
  )
}

export default BreadCrum