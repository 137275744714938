import GatewayPost from '../neulib/Gateway';
import DataSource from './DataSource';

  
const StoreSystem = {
  server : "",
  Get: (requestData, onSuccess, onError,token) => { GatewayPost(DataSource.NetCorePrd,"system_entity",requestData, onSuccess, onError,token)},
  Create: (requestData, onSuccess, onError,token) => { GatewayPost(DataSource.NetCorePrd,"system_create",requestData, onSuccess, onError,token)},
  Update: (requestData, onSuccess, onError,token) => { GatewayPost(DataSource.NetCorePrd,"system_update",requestData, onSuccess, onError,token)},
  List: (requestData, onSuccess, onError,token) => { GatewayPost(DataSource.NetCorePrd,"system_list",requestData, onSuccess, onError,token)},
  Disable: (requestData, onSuccess, onError,token) => { GatewayPost(DataSource.NetCorePrd,"system_disable",requestData, onSuccess, onError,token)}
};


export default StoreSystem;