import GatewayPost from '../neulib/Gateway';
import DataSource from './DataSource';

  
const StoreAccount= {
  server : "",
  Entity: (requestData, onSuccess, onError,token) => { GatewayPost(DataSource.NetCorePrd,"mdaccount_entity",requestData, onSuccess, onError,token)},
};


export default StoreAccount;