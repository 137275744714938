import React, { useState, forwardRef, useEffect,useRef } from 'react';
import { useAuth } from "../../../components/AuthHooks/AuthProvider.jsx";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import HandleInput  from '../../../components/neulib/InputHelper';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import StoreSystemDependencies  from '../../../components/stores/StoreSystemDependencies';
import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import ErrorTemplate  from '../../../components/shared/ErrorTemplate'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';

const OrgsystemDepends = forwardRef(({parentpk,dependencies,buttonaddlabel,systems,entitytype,RefreshEntity}, ref) => {
    const op = useRef(null);
    const toast = useRef(null);
    const buttonRef = useRef(null);
    const [visible, setVisible] = useState(false);
    const msgs = useRef(null);
    const [operation, setOperation] = useState("create");
    const [depenTitleLabel, setDepenTitleLabel] = useState("Add new");
    const editRef = useRef();
    const auth = useAuth();
    const [editorclass, setEditorclass] = useState("row");
    const [spinnerclass, setSpinnerclass] = useState("hidden");

    const [dependData, setDependData] = useState({
        name: "",
        description: "",
        comment: "",
      });

      const [isOverlayOpen, setIsOverlayOpen] = useState(false);

      const [selectedSystem, setSelectedSystem] = useState("");
    
      const handleOverlayToggle = (event) => {
        setIsOverlayOpen(false); 
      };



    const handleSaveButtonClick = (e) => {
        
        e.preventDefault();
        let errors = [];

        if (dependData.comment === "") {
            errors.push("Comment is empty");
        }

        if (errors.length === 0) {
     
            handleSaveOperation();

          return;
        }else{
          if (msgs.current) {
            msgs.current.clear();
            msgs.current.show({sticky: true, severity: 'error', summary: 'error', detail: editRef.current && editRef.current.buildErrors(errors), closable: false});
           }     
        }
      
      };

      const handleSaveOperation = () => {
        const operationFunction = operation === 'create' ? StoreSystemDependencies.Create : StoreSystemDependencies.Update;
      
        
        let requestdata = {
            parentpk : entitytype=="dependency"? selectedSystem : parentpk,
            public_key : entitytype=="dependent"? selectedSystem : parentpk,
            comment : dependData.comment
        }
        setEditorclass("hidden");
        setSpinnerclass("card");
        operationFunction(
            requestdata,
          (data) => {
            if (data.result==true){             
              if (msgs.current) {
                setEditorclass("row");
                setSpinnerclass("hidden");
                msgs.current.clear();
                msgs.current.show({sticky: true, severity: 'success', summary: 'Saving completed', detail: "System added successfuly", closable: false});
               }
              RefreshEntity();
              
            }else{
              if (msgs.current) {
                setEditorclass("row");
                setSpinnerclass("hidden");
                msgs.current.clear();
                msgs.current.show({sticky: true, severity: 'error', summary: 'error', detail: data.message, closable: false});
               }
            }

          },
          (error) => {
            if (msgs.current) {
              setEditorclass("row");
              setSpinnerclass("hidden");
              msgs.current.clear();
              msgs.current.show({sticky: true, severity: 'error', summary: 'error', detail: error, closable: false});
          }                        
          },
          auth.token
        );
      };


      const HandleEditRow = (e,rowData) => {
        setEditorclass("row");
        setSpinnerclass("hidden");
          setDependData({
            comment : rowData.comment
          });
          setSelectedSystem(rowData.public_key);    
          setVisible(true);
          setOperation("update");
          setDepenTitleLabel("Modify")
          //setSelectedSystem(rowData);
    };
      
    const accept2 = () => {
      toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'System successful disabled', life: 3000 });
  }
  
  const reject = () => {
      toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected the action', life: 3000 });
  };

    const confirmDelete = (griddata) => {
      confirmDialog({
        message: 'Do you want to disable this system?',
        header: 'Disable Confirmation',
        icon: 'pi pi-info-circle',
        defaultFocus: 'reject',
        acceptClassName: 'p-button-danger',
        accept: () => HandleRemoveRow(griddata),
        reject
      }); 
    };

    const HandleRemoveRow = (rowdata) => {
   
      let requestdata = {
        parentpk : entitytype=="dependency"? rowdata.public_key : parentpk,
        public_key : entitytype=="dependent"? rowdata.public_key : parentpk
    }

      StoreSystemDependencies.Disable(
       requestdata,
        (data) => {
           
          accept2(); 
          RefreshEntity();
          //setRowData(null);
        },
        (error) => {
          console.error("Error fetching data:", error);
          let errors = [error];
        },
        auth.token
      );
     };

      const actionBodyTemplate = (rowData) => {
        //return <span>{rowData.name}</span>;
        return <>
                <Button onClick={(e) => HandleEditRow(e,rowData)} icon="pi pi-pen-to-square" severity='info' style={{ fontSize: '1rem' }}  aria-label="Filter" />
                <Button onClick={() =>  confirmDelete(rowData)} icon="pi pi-trash" severity='danger' style={{ fontSize: '1rem' }}  aria-label="Filter" />
        </>
      };

      const handleSystemChange = (e) => {
        setSelectedSystem(e.value);
      };

      const handleButtonClick = (e) => {
        setDepenTitleLabel("Create")
        setOperation("create");
        // Handle button click action
        setVisible(true);
      };


      const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
         
            <span className="font-bold white-space-nowrap">{depenTitleLabel} </span>
        </div>
    );


      
  return (
    <div>

<Toast ref={toast} />

            <ErrorTemplate ref={editRef} ></ErrorTemplate>

            <Button ref={buttonRef}  type="button" icon="pi pi-plus" label={buttonaddlabel} onClick={(e) => handleButtonClick(e)}  autoFocus/>

            <Dialog visible={visible} modal header={headerElement} style={{ width: '50rem' }} onHide={() => setVisible(false)}>
  
            <div className={spinnerclass}>
                  <ProgressSpinner aria-label="Loading" style={{width: '100px', height: '100px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
            </div>

            <div id="editor-info" >
<div className={editorclass}>


            <div className="mb-2 row">
           
                <div className="col-md-10">
                <Button type="button" severity='secondary' icon="pi pi-home" label="close"  onClick={(e) => setVisible(false)}   />
                <Button type="button" severity='success' icon="pi pi-save" label="save"  onClick={handleSaveButtonClick}  />
              
                </div>
            </div>


            <Messages ref={msgs} /> 

            <div className="mb-2 row">
                <label className="col-md-2 col-form-label">Extenal System</label>
                <div className="col-md-10">
                    <div className="input-group">
                    <Dropdown value={selectedSystem}  onChange={handleSystemChange}
                    options={systems} optionLabel="name" optionValue="public_key" placeholder="Select a system" 
                filter 
                 className="w-full" />
                    </div>
                </div>
            </div>
            
            <div className="mb-2 row">
                <label className="col-md-2 col-form-label">Comment</label>
                <div className="col-md-10">
                    <div className="input-group">
                        <InputText name="comment"  className="p-inputtext-sm form-control"  value={dependData.comment} onChange={(e) => HandleInput(e, setDependData)}  ></InputText >
                    </div>
                </div>
            </div>
            </div>

            </div>


            </Dialog>


            <DataTable value={dependencies} stripedRows tableStyle={{ minWidth: '20rem' }}>
               
                <Column field="name" header="Name"></Column>
                <Column field="description" header="Description"></Column>
                <Column field="comment" header="comment"></Column>
                <Column  body={actionBodyTemplate}></Column>
  
            </DataTable>

    </div>
  )
});

export default OrgsystemDepends