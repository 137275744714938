import React  from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/dashboard";
import Profile from "./pages/profile/profile";
import Orgsystem from './pages/catalogs/orgsystem/orgsystem';

import AuthProvider from "./components/AuthHooks/AuthProvider";
import PrivateRoute from "./components/router/PrivateRoute";


function App() {
  return (
    <div>
      <Router>
        <AuthProvider>
          <Routes>
          <Route>
              <Route path="/" element={<Dashboard />} />
            </Route>       
            <Route path="/login" element={<Login />} />
            <Route>
              <Route path="/dashboard" element={<Dashboard />} />
            </Route>
            <Route>
              <Route path="/profile" element={<Profile />} />
            </Route>
            <Route>
              <Route path="/perfil" element={<Profile />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route path="/systems" element={<Orgsystem />} />
            </Route>
            {/* Other routes */}
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
