import React from 'react'
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';


const Sidebar = () => {
  return (

    <div class="left-side-menu">


        <div class="logo-box">
            <a href="index.html" class="logo logo-dark text-center">
                <span class="logo-sm">
                  
                     <span class="logo-lg-text-light">RiskWire</span> 
                </span>
                <span class="logo-lg">
                 
                    <span class="logo-lg-text-light">R</span> 
                </span>
            </a>

            <a href="index.html" class="logo logo-light text-center">
                <span class="logo-sm">
                    <Image src="assets/images/logo-sm.png" alt="" height="24" />
                </span>
                <span class="logo-lg">
                    <Image src="assets/images/logo-light.png" alt="" height="20" />
                </span>
            </a>
        </div>

        <div class="h-100" data-simplebar>

       
            <div class="user-box text-center">
                <Image src="assets/images/users/avatar-1.jpg" alt="user-img" title="Mat Helme"
                    class="rounded-circle avatar-md" />
                <div class="dropdown">
                    <a href="#" class="text-reset dropdown-toggle h5 mt-2 mb-1 d-block"
                        data-bs-toggle="dropdown">Nik Patel</a>
                    <div class="dropdown-menu user-pro-dropdown">

                    
                        <a href="javascript:void(0);" class="dropdown-item notify-item">
                            <i class="fe-user me-1"></i>
                            <span>My Account</span>
                        </a>

                    
                        <a href="javascript:void(0);" class="dropdown-item notify-item">
                            <i class="fe-settings me-1"></i>
                            <span>Settings</span>
                        </a>

                     
                        <a href="javascript:void(0);" class="dropdown-item notify-item">
                            <i class="fe-lock me-1"></i>
                            <span>Lock Screen</span>
                        </a>

           
                        <a href="javascript:void(0);" class="dropdown-item notify-item">
                            <i class="fe-log-out me-1"></i>
                            <span>Logout</span>
                        </a>

                    </div>
                </div>
                <p class="text-reset">Admin Head</p>
            </div>

            <div id="sidebar-menu">

                <ul id="side-menu">

                    <li class="menu-title">Navigation</li>
        
                    <li>
                        <Link to="/"  class="waves-effect">
                            <i class="mdi mdi-view-dashboard"></i>
                            <span> Dashboard </span>
                        </Link>
                    </li>

                    <li>
                        <a href="#sidebarLayouts" data-bs-toggle="collapse" aria-expanded="false" aria-controls="sidebarLayouts">
                            <i class="mdi mdi-cellphone-link"></i>
                            <span> Catalogs </span>
                            <span class="menu-arrow"></span>
                        </a>
                        <div class="collapse" id="sidebarLayouts">
                            <ul class="nav-second-level">
                                <li>
                                    <Link to="/systems">Organization Systems</Link>
                                </li>
                            </ul>
                        </div>
                    </li>

                </ul>

            </div>

            <div class="clearfix"></div>

        </div>

    </div>


  )
}

export default Sidebar