import React from "react";
import Navbar from '../navbar/navbar'
import Breadcrumb from '../shared/BreadCrum'
import Sidebar from "../mainmenu/sidebar";

const ViewTemplate = ({ breadcrum, showEditor, handleAddButtonClick, handleBackButtonClick, children }) => {
  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <Breadcrumb breadcrum={breadcrum} />
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewTemplate;
