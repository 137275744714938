import GatewayPost from '../neulib/Gateway';
import DataSource from './DataSource';

const StoreBusinessUnitsDependencies = {
  server : "",
  Create: (requestData, onSuccess, onError,token) => { GatewayPost(DataSource.NetCorePrd,"bunit_depen_create",requestData, onSuccess, onError,token)},
  Update: (requestData, onSuccess, onError,token) => { GatewayPost(DataSource.NetCorePrd,"bunit_depen_update",requestData, onSuccess, onError,token)},
  Disable: (requestData, onSuccess, onError,token) => { GatewayPost(DataSource.NetCorePrd,"bunit_depen_disable",requestData, onSuccess, onError,token)},
};

export default StoreBusinessUnitsDependencies;