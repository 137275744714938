import React, { useState, useEffect ,forwardRef} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import debounce from 'lodash.debounce';
import { useAuth } from "../AuthHooks/AuthProvider.jsx";

const Grid = forwardRef(({ Datasource, columns, rowsPerPage, fetchUrl, Rid, appid, org,size },ref) => {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(rowsPerPage);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const auth = useAuth();
  const url = `${Datasource}/${fetchUrl}`;

  useEffect(() => {
    fetchData();
  }, [first, rows]);

  useEffect(() => {
    ref.current = {
      fetchData      
    };
  }, [ref,first, rows]);

  const fetchData = (search='') => {
    //setLoading(true);

    const requestData = {
      Rid,
      appid,
      org,
      currentpage: (first / rows) + 1,
      start: first,
      length: rows,
      search: { value : search },
      
      columns: columns.filter(col => col.field !== null).map(col => ({ data: col.field, header: col.header, sortable : col.sortable,colwidth : col.colwidth ,searchable : col.searchable })),
      RequestData: JSON.stringify({})
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth.token
      },
      body: JSON.stringify(requestData)
    })
      .then(response => response.json())
      .then(data => {
        setData(data.data);
        setTotalRecords(data.recordsTotal);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        //setLoading(false);
      });
  };

  const onPageChange = event => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
  const paginatorRight = <Button type="button" icon="pi pi-download" text />;

  const onGlobalFilterChange = (e) => {  
    const value = e.target.value;
    setGlobalFilterValue(value);
    
    const debounced = debounce(() => {
      fetchData(value); 
    }, 500);
    
    debounced();

};

const [globalFilterValue, setGlobalFilterValue] = useState('');
const [filters, setFilters] = useState({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },

});


  const renderHeader = () => {
    return (
        <div className="flex justify-content-end">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={e => onGlobalFilterChange(e)} placeholder="Keyword Search" />
            </span>
        </div>
    );
};

const header = renderHeader();

  return (
    <div>
      <div className="card">
        <DataTable
          value={data}
          loading={loading}
          header={header}
          filters={filters}
          filterDisplay="row" 
          globalFilterFields={['name', 'description']}
          sortMode="multiple"
          removableSort 
          first={first}
          rows={rows}
          size="small"
          stripedRows 
          rowsPerPageOptions={[5, 10, 25, 50,100]} tableStyle={{ minWidth: '50rem' }}
          paginator
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink NextPageLink LastPageLink CurrentPageReport"
          currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
          lazy
          totalRecords={totalRecords}
          onPage={onPageChange}
        >
          {columns.map(col => (
            <Column key={col.field} field={col.field} header={col.header} sortable= {col.sortable}  body={col.body} style={{ width: col.colwidth}} />
          ))}
        </DataTable>
      </div>
    </div>
  );
}
);

export default Grid;
