import GatewayPost from '../neulib/Gateway';
import DataSource from './DataSource';

  
const StoreCategory = {
  server : "",
  List: (requestData, onSuccess, onError,token) => { GatewayPost(DataSource.NetCorePrd,"mdcategory_list",requestData, onSuccess, onError,token)},
};


export default StoreCategory;