import React, { useState, forwardRef, useEffect,useRef } from 'react';
import ViewEditTemplate  from '../../../components/shared/ViewEditTemplate'
import ErrorTemplate  from '../../../components/shared/ErrorTemplate'
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import StoreSystem  from '../../../components/stores/StoreSystem';
import StoreSystemBusinessUnits  from '../../../components/stores/StoreSystemBusinessUnits';
import HandleInput  from '../../../components/neulib/InputHelper';
import { useMountEffect } from 'primereact/hooks';
import { InputText } from 'primereact/inputtext';
import { Messages } from 'primereact/messages';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import OrgsystemDepends from './orgsystemDepends'
import OrgsystemComponents from './orgsystemComponents'
import OrgsystemBusinessUnit from './orgsystemBusinessUnit'
import { useAuth } from "../../../components/AuthHooks/AuthProvider.jsx";
import { Dropdown } from 'primereact/dropdown';

const OrgsystemEditor = forwardRef(({breadcrumCreate,setBreadcrum,breadcrumEditor,handleBackButtonClick,showEditor,setShowEditor}, ref) => {
    const editRef = useRef();
    const msgs = useRef(null);
    const op = useRef(null);
    const auth = useAuth();
    const [classnameHidden, setclassnameHidden] = useState("hidden");
    const [saveError, setSaveError] = useState("");
    const [selectedSystem, setSelectedSystem] = useState("");

    const [inputValue, setInputValue] = useState("");
    const [editorMode, setEditorMode] = useState("");
    const [systemData, setSystemData] = useState([]);
    const [businessUnitData, setBusinessUnitData] = useState([]);
    const [entityData, setEntityData] = useState({
        public_key: "",
        name: "",
        description: "",
        parentpk:"",
        components:[],
        dependencies: [],
        dependendants: [],
        businessunits: []
      });

      useEffect(() => {
        ref.current = {
            GetEntity,
            hideSpinner,
            EditorNew,          
        };
        
      }, [ref]);
      
    const EditorNew = () => {
        msgs.current.clear();
        setEntityData({
            public_key: "",
            name: "",
            description: "",
            parentpk:"",
            components:[],
            dependencies: [],
            dependendants: [],
            businessunits: []
          });
        editRef.current && editRef.current.buildErrors([]);
        GetListSystem();
        GetListBU();
        setEditorMode("create");
        setclassnameHidden("");
    }

      
    const handleSystemChange = (e) => {
        setEntityData({
            public_key: entityData.public_key,
            name: entityData.name,
            description: entityData.description,
            parentpk:e.value,
            components : entityData.components,
            dependencies: entityData.dependencies,
            dependendants: entityData.dependendants,
            businessunits: entityData.businessunits
          });
    };

    const hideSpinner = () => {
        setclassnameHidden("");
    }

    const RefreshEntity = () =>{
        GetEntity(entityData.public_key);
    }

    const GetEntity = (pk) => {
        msgs.current.clear();
        GetListSystem();
        GetListBU();
        if (pk===undefined) return;

        setclassnameHidden("hidden");
  
        var requestdata = {
            public_key : pk
        }

        StoreSystem.Get(
            requestdata,
            (data) => {
                console.log(data.data)
                setEntityData(data.data);
                //setSelectedSystem(data.data.parentpk);    
                editRef.current && editRef.current.buildErrors([]);
           
                setEditorMode("edit");
                setBreadcrum(breadcrumEditor);
                setclassnameHidden("");
              },
              (error) => {
                console.error("Error fetching data:", error);
                setclassnameHidden("");
              } ,
              auth.token     
            );
          
    };


    const GetListSystem = () => {
        msgs.current.clear();
    
        if (systemData.length!==0){
            return;
        }
        var requestdata = {
        }

        StoreSystem.List(
            requestdata,
            (data) => {
                setSystemData(data.data);
              },
              (error) => {
                console.error("Error fetching data:", error);
                setclassnameHidden("");
              },
              auth.token   
            );
          
    };

    const GetListBU = () => {
        msgs.current.clear();
    
        if (systemData.length!==0){
            return;
        }
        var requestdata = {
        }

        StoreSystemBusinessUnits.List(
            requestdata,
            (data) => {
                setBusinessUnitData(data.data);
              },
              (error) => {
                console.error("Error fetching data:", error);
                setclassnameHidden("");
              } ,
              auth.token  
            );
          
    };

      const handleSaveButtonClick = (e) => {
        

        e.preventDefault();
        let errors = [];
        if (entityData.name === "") {
            errors.push("Name is empty");
        }
        if (entityData.description === "") {
            errors.push("Description is empty");
        }

        if (errors.length === 0) {
            setclassnameHidden("hidden");
            handleSaveOperation(editorMode);
          return;
        }else{
           
            if (msgs.current) {
                msgs.current.clear();
                msgs.current.show({ id: '1', sticky: true, severity: 'error', summary: 'Please check the errors:', detail:  editRef.current && editRef.current.buildErrors(errors), closable: false });
            }
            setclassnameHidden("");
        }
      
      };

      const handleSaveOperation = (operation) => {
        const operationFunction = operation === 'create' ? StoreSystem.Create : StoreSystem.Update;
      
        operationFunction(
          entityData,
          (data) => {
            setEntityData(data.data);
            editRef.current && editRef.current.buildErrors([]);
            setEditorMode("edit");
            setBreadcrum(breadcrumEditor);
            setclassnameHidden("");
            if (msgs.current) {
                msgs.current.clear();
                msgs.current.show({sticky: true, severity: 'success', summary: 'Success', detail: 'System successful saved', closable: false});
            }
          },
          (error) => {
            console.error("Error fetching data:", error);
            let errors = [error];
            editRef.current && editRef.current.buildErrors(errors);  
            setclassnameHidden("");
          },
          auth.token
        );
      };

    const itemTemplate = (dependency, index) => {
        return (
            <tr>
            <td>{dependency.name}</td>
            <td>{dependency.description}</td>
            <td>{dependency.comment}</td>
        </tr>
        );
    };

    const listTemplate = (items) => {
        if (!items || items.length === 0) return null;

        let list = items.map((dependency, index) => {
            return itemTemplate(dependency, index);
        });

        return     <div class="table-responsive">
        <table id="id_table" class="table table-hover m-0 table-centered dt-responsive w-100 dataTable no-footer dtr-inline collapsed">
            <thead class="table-light">
                <tr class="">

                    <th scope="col">System</th>
                    <th scope="col">Description</th>
                    <th scope="col">Purpose</th>
                </tr>
            </thead>
            <tbody id="id_append">
                {list}
            </tbody>
        </table>
    </div>
    };

    const itemUnitTemplate = (row, index) => {
        return (
            <tr>
            <td>{row.name}</td>
      
            <td>{row.comment}</td>
        </tr>
        );
    };

    const listUnitTemplate = (items) => {
        if (!items || items.length === 0) return null;

        let list = items.map((row, index) => {
            return itemUnitTemplate(row, index);
        });

        return   <div class="table-responsive">
        <table id="bu_table" class="table table-hover m-0 table-centered dt-responsive w-100 dataTable no-footer dtr-inline collapsed">
            <thead class="table-light">
                <tr class="">

                    <th scope="col">Business Unit</th>
        
                    <th scope="col">Purpose</th>
                </tr>
            </thead>
            <tbody id="bunit_append">
                {list}
            </tbody>
        </table>
        </div>
    };

  return (

        <ViewEditTemplate
        showEditor={showEditor}
        classnameHidden={classnameHidden}
        handleSaveButtonClick={handleSaveButtonClick}
        handleBackButtonClick={handleBackButtonClick}
        >
            <Messages ref={msgs} /> 
            <ErrorTemplate saveError={saveError} ref={editRef} ></ErrorTemplate>
            
            <div className="mb-2 row">
                <label className="col-md-2 col-form-label">Parent System</label>
                <div className="col-md-10">
                    <div className="input-group">
                    <Dropdown value={entityData.parentpk}  onChange={handleSystemChange}
                    options={systemData} optionLabel="name" optionValue="public_key" placeholder="Select a system" 
                filter 
                 className="w-full" />
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-2 col-form-label">Public Key</label>
                <div className="col-md-10">
                    <div className="input-group">
                        <InputText  id="isys_publickey" name="publickey" disabled="disabled" className="form-control" value={entityData.public_key} onChange={(e) => HandleInput(e, setEntityData)}  ></InputText >
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-2 col-form-label">Name</label>
                <div className="col-md-10">
                    <div className="input-group">
                        <InputText  id="isys_name" name="name" className="p-inputtext-sm form-control" value={entityData.name} onChange={(e) => HandleInput(e, setEntityData)} ></InputText >
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <label className="col-md-2 col-form-label">Description</label>
                <div className="col-md-10">
                    <div className="input-group">
                        <InputText  id="isys_description" name="description"  className="p-inputtext-sm form-control" value={entityData.description} onChange={(e) => HandleInput(e, setEntityData)}  ></InputText >
                    </div>
                </div>
            </div>
                
            {editorMode=='edit'  && (     
            <div className="card">
            <TabView>
                <TabPanel header="External Dependencies">

                <OrgsystemDepends parentpk={entityData.public_key} dependencies={entityData.dependencies} buttonaddlabel="Add dependencies" systems={systemData} entitytype="dependency" RefreshEntity={RefreshEntity}  /> 

                </TabPanel>
                <TabPanel header="External Dependents">
              
                <OrgsystemDepends parentpk={entityData.public_key} dependencies={entityData.dependendants} buttonaddlabel="Add dependents" systems={systemData} entitytype="dependent" RefreshEntity={RefreshEntity}/> 

                </TabPanel>
                <TabPanel header="Business Units">

                <OrgsystemBusinessUnit parentpk={entityData.public_key} businessunits={entityData.businessunits} buttonaddlabel="Add business units" businessunitsSelector={businessUnitData} entitytype="businessunit" RefreshEntity={RefreshEntity}/> 


                </TabPanel>

                <TabPanel header="Subsystems/Components">
                <OrgsystemComponents parentpk={entityData.public_key} components={entityData.components} buttonaddlabel="Add component" componentsSelector={systemData} entitytype="businessunit" RefreshEntity={RefreshEntity}/> 

                </TabPanel>

            </TabView>
            </div>
            )}
      
        </ViewEditTemplate>


  )
});

export default OrgsystemEditor