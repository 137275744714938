import React, { useState, forwardRef, useEffect,useRef } from 'react';
import Mainmenu from '../../components/mainmenu/mainmenu'
import Navbar from '../../components/navbar/navbar'
import SearchMain from '../../components/search/searchmain'
import CategoryList from '../../components/listing/categorylist'
import TopList from '../../components/listing/toplist'
import Breadcrumb from '../../components/shared/BreadCrum'
import Sidebar from "../../components/mainmenu/sidebar";
import StoreAccount  from '../../components/stores/StoreAccount';
import { useLocation } from 'react-router-dom';
import $ from 'jquery';
// Import bxSlider JS and CSS
import 'bxslider/dist/jquery.bxslider.min.js';
import 'bxslider/dist/jquery.bxslider.css';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

const Profile = () => {
    const [entity, setEntity] = useState({
        account : {},
        childs :[],
        specialities : []
    });

    const [services, setServices] = useState([]);

    const query = useQuery();
    const p = query.get('p');
 
    useEffect(() => {
        const fetchProfile = async () => {
            var requestdata = { "public_key": p};

            StoreAccount.Entity(
                requestdata,
                (data) => {
                    console.log(data.data);
                    setEntity(data.data);

                    var services = data.data.account.services.split("|");
                    console.log(services);
                    setServices(services);
                },
                (error) => {
                    console.error("Error fetching data:", error);
                } ,
                );
        };

    fetchProfile();
      
  }, []);


  const [breadcrum, setBreadcrum] = useState({
    view : "Profile",
    menu: "Catalogs",
    mode : "",
    module : "Profile"
  });

  return (
    <div id="wrapper2">

        <Navbar />

        <br/>
        
        <div class="container">
            <div class="row">
                <div class="col-md-12">

                    <nav aria-label="breadcrumb" role="navigation" class="pull-left">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#"><i class="icon-home fa"></i></a></li>
                            <li class="breadcrumb-item"><a href="category.html">All Ads</a></li>
                            <li class="breadcrumb-item active" aria-current="page">Electronics</li>
                        </ol>
                    </nav>


                    <div class="pull-right backtolist"><a href="sub-category-sub-location.html"> <i class="fa fa-angle-double-left"></i> Back to Results</a></div>

                </div>
            </div>
        </div>

        <div className="container">

            
      <div className="row">
        <div className="col-md-9 page-content col-thin-right">
          <div className="inner inner-box ads-details-wrapper">
            <h2>
            {entity.account.name}
              <small className="label label-default adlistingtype"> {entity.account.subcat_name} </small>
            </h2>
            <span className="info-row">
              <span className="date">
                <i className="icon-clock"></i> 
              </span>
              - 
              <span className="category">{entity.account.cat_name}  </span>
              - 
              <span className="item-location">
                <i className="fa fa-map-marker-alt"></i>  {entity.account.zone_name} 
              </span>
            </span>
            <div className="item-slider">
            
              <ul class="bxslider">
                <li><img src="/assets/images/item/tp-big/Image00015.jpg" alt="img" /></li>
                <li><img src={entity.account.profile_img} alt="img" /></li>
                <li><img src={entity.account.profile_img} alt="img" /></li>
              </ul>
              <div id="bx-pager">
                <a className="thumb-item-link" data-slide-index="0" href="">
                  <img src="/assets/images/item/tp/Image00014.jpg" alt="img" />
                </a>
                <a className="thumb-item-link" data-slide-index="1" href="">
                  <img src={entity.account.profile_img}  alt="img" />
                </a>
                <a className="thumb-item-link" data-slide-index="2" href="">
                  <img src={entity.account.profile_img}  alt="img" />
                </a>
                <a className="thumb-item-link" data-slide-index="2" href="">
                  <img src={entity.account.profile_img}  alt="img" />
                </a>
              </div>
            </div>

            <div className="Ads-Details">
              <h5 className="list-title">
                <strong>Detalle</strong>
              </h5>

              <div className="row">
                <div className="ads-details-info col-md-8">
                  <p>
                  {entity.account.description}
                  </p>
                 

                
                  {/* <h4>Networks</h4>
                  <ul>
                    <li>GSM GPRS/EDGE (2G)</li>
                    <li>UMTS HSPA (3G)</li>
                    <li>LTE (4G)</li>
                  </ul>
                   */}

                </div>
                <div className="col-md-4">
                  <aside className="panel panel-body panel-details">
                    <ul>
                      {/* <li>
                        <p className="no-margin">
                          <strong>Price:</strong> $2,45
                        </p>
                      </li>
                      <li>
                        <p className="no-margin">
                          <strong>Type:</strong> Mobile Mobiles, For sale
                        </p>
                      </li> */}
                      <li>
                        <p className="no-margin">
                          <strong>Location:</strong>  {entity.account.zone_name}
                        </p>
                      </li>
                      {/* <li>
                        <p className="no-margin">
                          <strong>Condition:</strong> New
                        </p>
                      </li>
                      <li>
                        <p className="no-margin">
                          <strong>Brand:</strong> Sony
                        </p>
                      </li> */}
                    </ul>
                  </aside>
                  <div className="ads-action">
                    <ul className="list-border">
                      <li><a href="#"> <i className="fa fa-user"></i> More ads by User </a></li>
                      <li><a href="#"> <i className="fa fa-heart"></i> Save ad </a></li>
                      <li><a href="#"> <i className="fa fa-share-alt"></i> Share ad </a></li>
                      <li>
                        <a data-bs-target="#reportAdvertiser" data-bs-toggle="modal">
                          <i className="fa icon-info-circled-alt"></i> Report abuse
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="col-md-3 page-sidebar-right">
          <aside>
            <div className="card card-user-info sidebar-card">
 
              <div className="card-content">
                <div className="card-body text-left">
                  <div className="grid-col">
                    
                  <h4>Servicios</h4>
                <ul className="list-circle">
                    {services.map((service, index) => (
                        <li>{service}</li>               
                    ))}
                </ul>   

                  </div>

          
                </div>

                <div className="ev-action">
                  <a className="btn btn-success btn-block" data-bs-toggle="modal" data-bs-target="#contactAdvertiser">
                    <i className="icon-mail-2"></i> Contact User
                  </a>
                  <a className="btn btn-info btn-block">
                    <i className="icon-phone-1"></i> {entity.account.telephone}
                  </a>
                </div>
              </div>
            </div>

            <div className="card sidebar-card">
              <div className="card-header">Safety Tips for Buyers</div>
              <div className="card-content">
                <div className="card-body text-left">
                  <ul className="list-check">
                    <li>Meet seller at a public place</li>
                    <li>Check the item before you buy</li>
                    <li>Pay only after collecting the item</li>
                  </ul>
                  <p>
                    <a className="float-right" href="#">
                      Know more <i className="fa fa-angle-double-right"></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </div>


    </div> 
  )
}

export default Profile