import React, { useState, forwardRef, useEffect,useRef } from 'react';
import StoreCategory  from '../../components/stores/StoreCategory';


const CategoryList = () => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            var requestdata = {

            }

            StoreCategory.List(
                requestdata,
                (data) => {
                    console.log(data.data);
                    setCategories(data.data);
                  },
                  (error) => {
                    console.error("Error fetching data:", error);
                  } ,
                );
        };
    
        fetchCategories();
      }, []);


  return (

        <div className="col-xl-12 content-box">
          <div className="row row-featured row-featured-category">
            <div className="col-xl-12 box-title no-border">
              <div className="inner">
                <h2>
                  <span>Navegar por</span> categoría{' '}
                  <a href="category.html" className="sell-your-item">
                    View more <i className="icon-th-list"></i>
                  </a>
                </h2>
              </div>
            </div>

            {categories.map((category, index) => (
              <div key={index} className="col-xl-2 col-md-3 col-sm-3 col-xs-4 f-category">
                <div className="inner">
                  <a href={category.link}>
                    <span className="imgBox">
                      <img
                        src={`assets/images/category/${category.img_static_route}`}
                        className="img-responsive"
                        alt={category.name}
                      />
                    </span>
                    <h6>{category.name}</h6>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>

  );
};

export default CategoryList;
