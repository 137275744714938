import React, { useState, forwardRef, useEffect } from 'react';

const ErrorTemplate = forwardRef(({saveError}, ref) => {

    const [errors, setErrors] = useState([]);

    useEffect(() => {
        ref.current = {
            buildErrors
        };
    }, [ref]);

    const buildErrors = (errors) => {
        //setErrors(errors)

        return (
            errors.map((error, index) => (
                <div key={index}>{error}</div>
            ))
        );
    }

    
/* 

  return (
    <div className="error-box">
    {errors.length>0 && 
                        <div class="alert alert-danger bg-danger text-white border-0 fade show" role="alert">
                     
                        {errors.map((error, index) => (
                            <div key={index}>{error}</div>
                        ))}

                     </div>
}
</div>
  )
*/
}); 

export default ErrorTemplate