import React from "react";
import Image from 'react-bootstrap/Image';

const LoginForm = ({ handleSubmitEvent, handleInput, input,emailError  }) => {
  return (
    <>
            <div className="auth-fluid">
       
            <div className="auth-fluid-right bg-transparent">
                <div className="auth-user-testimonial">
                    <h3 className="mb-3 text-white">Very elegant & impressive!</h3>
                    <p className="lead fw-normal"><i className="mdi mdi-format-quote-open"></i> I've been using this theme for a while and I must say that whenever I am looking for a design - I refer to this theme for specifics & implementation. With wide arrays of components, designs, charts - I would highly recommend this theme for anyone using it for dashboard or project management usage.. <i className="mdi mdi-format-quote-close"></i>
                    </p>
                    <h5 className="text-white">
                        - Admin User
                    </h5>
                </div> 
            </div>

            <div className="auth-fluid-form-box">
                <div className="align-items-center d-flex h-100">
                    <div className="card-body">

       
                        <div className="auth-brand text-center text-lg-start">
                            <div className="auth-logo">
                                <a href="index.html" className="logo logo-dark text-center">
                                    <span className="logo-lg">
                                        <Image src="assets/images/logo-dark.png" alt="" height="22" />
                                    </span>
                                </a>
            
                                <a href="index.html" className="logo logo-light text-center">
                                    <span className="logo-lg">
                                        <Image src="assets/images/logo-light.png" alt="" height="22" />
                                    </span>
                                </a>
                            </div>
                        </div>

           
                        <h4 className="mt-0">Sign In</h4>
                        <p className="text-muted mb-4">Enter your email address and password to access admin panel.</p>



                                        <div className="error-box">
                                            {emailError && 
                                                                <div class="alert alert-danger alert-dismissible bg-danger text-white border-0 fade show" role="alert">
                                                                <button type="button" class="btn-close"></button>
                                                                {emailError}
                                                             </div>
                                        }
                                        </div>

                        <form onSubmit={handleSubmitEvent}>
                            <h3 className="mb-3">{emailError}</h3>

                            <div className="mb-2">
                                <label for="emailaddress" className="form-label">Email address</label>
                 
                                <input
          type="text"
          id="email"
          name="email"
          className="form-control"
          placeholder="example@yahoo.com"
          aria-describedby="user-email"
          aria-invalid="false"
          onChange={handleInput}
          value={input.email} />
                            </div>
                            <div className="mb-2">
                                <a href="auth-recoverpw-2.html" className="text-muted float-end"><small>Forgot your password?</small></a>
                                <label for="password" className="form-label">Password</label>
                                <div className="input-group input-group-merge">
                         

                                    <input
          type="password"
          id="password"
          name="password"
          className="form-control"
          aria-describedby="user-password"
          aria-invalid="false"
          onChange={handleInput}
          value={input.password} />                     
                                    <div className="input-group-text" data-password="false">
                                        <span className="password-eye"></span>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="mb-3">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="checkbox-signin" />
                                    <label className="form-check-label" for="checkbox-signin">
                                        Remember me
                                    </label>
                                </div>
                            </div>
                            <div className="d-grid text-center">
                                <button className="btn-submit btn btn-primary">Log In </button>
                            </div>
             
                            <div className="text-center mt-4">
                                <h5 className="mt-0 text-muted">Sign in with</h5>
                                <ul className="social-list list-inline mt-3 mb-0">
                                    <li className="list-inline-item">
                                        <a href="javascript: void(0);" className="social-list-item border-purple text-purple"><i className="mdi mdi-facebook"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="javascript: void(0);" className="social-list-item border-danger text-danger"><i className="mdi mdi-google"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="javascript: void(0);" className="social-list-item border-info text-info"><i className="mdi mdi-twitter"></i></a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="javascript: void(0);" className="social-list-item border-secondary text-secondary"><i className="mdi mdi-github"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </form>
  
                        <footer className="footer footer-alt">
                            <p className="text-muted">Don't have an account? <a href="auth-register-2.html" className="text-primary fw-medium ms-1">Sign Up</a></p>
                        </footer>

                    </div> 
                </div> 
            </div>
            
        </div>
        

    </>
   
  );
};

export default LoginForm;
