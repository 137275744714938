import GatewayPost from '../neulib/Gateway';
import DataSource from './DataSource';

const StoreSystemDependency = {
  server : "",
  Create: (requestData, onSuccess, onError,token) => { GatewayPost(DataSource.NetCorePrd,"system_dependency_create_service",requestData, onSuccess, onError,token)},
  Update: (requestData, onSuccess, onError,token) => { GatewayPost(DataSource.NetCorePrd,"system_dependency_update_service",requestData, onSuccess, onError,token)},
  Disable: (requestData, onSuccess, onError,token) => { GatewayPost(DataSource.NetCorePrd,"system_dependency_disable_service",requestData, onSuccess, onError,token)},
};


export default StoreSystemDependency;