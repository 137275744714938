import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './components/shared/default.scss'


//import './components/theme/_variables-custom.scss';


const root = ReactDOM.createRoot(document.getElementById('wrapper'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);