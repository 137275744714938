import { useState } from "react";
import { useAuth } from "../../components/AuthHooks/AuthProvider.jsx";
import LoginForm from "./LoginView.jsx";

const Login = () => {
  const [input, setInput] = useState({
    email: "",
    password: "",
  });

  const [emailError, setEmailError] = useState("");

  const auth = useAuth();

  const handleSubmitEvent = (e) => {
    e.preventDefault();
    console.log(input.email);
    if (input.email !== "" && input.password !== "") {
      if (auth.loginAction(input)!==true){
        setEmailError("Invalid credentials");
      }
      return;
    }else{
      setEmailError("Please enter a valid email address.");
    }
   
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <LoginForm
      handleSubmitEvent={handleSubmitEvent}
      handleInput={handleInput}
      input={input}
      emailError={emailError}
    />
  );
};

export default Login;