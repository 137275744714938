import GatewayPost from '../neulib/Gateway';
import DataSource from './DataSource';

  
const StoreListing= {
  server : "",
  TopList: (requestData, onSuccess, onError,token) => { GatewayPost(DataSource.NetCorePrd,"mdaccount_top_list",requestData, onSuccess, onError,token)},
};


export default StoreListing;