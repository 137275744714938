import { useAuth } from "../AuthHooks/AuthProvider.jsx";

import config from '../../config';

const GatewayPost = (DataSource,Rid,data, onSuccess, onError,token) => {
  //const Rid = "system_entity";
  const appid = config.fetchconfig.appid;  //"riskwire";
  const org = config.fetchconfig.org;
  const Rpu = token;

  const requestData = {
    Rid,
    appid,
    org,
    Rpu,
    RequestData: JSON.stringify(data),
  };

  const url = `${DataSource}/${config.Negotiate}`;
  console.log(config.Negotiate);
  
  //fetch(config.Negotiate, {
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + token
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => response.json())
    .then((data) => {
      onSuccess(data);
    })
    .catch((error) => {
      onError(error);
    });
};



export default GatewayPost;
  