import React, { useState, forwardRef, useEffect,useRef } from 'react';
import StoreListing  from '../../components/stores/StoreListing';
import { Link  } from 'react-router-dom';

const TopList = () => {

    const [listing, setListing] = useState([]);

    useEffect(() => {
        const fetchListing = async () => {
            var requestdata = "all"

            StoreListing.TopList(
                requestdata,
                (data) => {
                    console.log(data.data);
                    setListing(data.data);
                  },
                  (error) => {
                    console.error("Error fetching data:", error);
                  } ,
                );
        };
    
        fetchListing();
      }, []);

  return  (

    <div className="col-xl-12 content-box">
      <div className="row row-featured">
        <div className="col-xl-12 box-title">
          <div className="inner">
            <h2>
              <span>Empresas </span> Patrocinadoras{' '}
              <a href="category.html" className="sell-your-item">
                ver mas <i className="icon-th-list"></i>
              </a>
            </h2>
          </div>
        </div>

        <div style={{ clear: 'both' }}></div>

        <div className="relative content featured-list-row w100">
          <nav className="slider-nav has-white-bg nav-narrow-svg">
            <a className="prev">
              <span className="nav-icon-wrap"></span>
            </a>
            <a className="next">
              <span className="nav-icon-wrap"></span>
            </a>
          </nav>

          <div className="no-margin featured-list-slider owl-carousel owl-theme" style={{ opacity: 1, display: 'block' }}>
            <div className="owl-wrapper-outer">
              <div className="owl-wrapper" style={{ width: '3216px', left: '0px', display: 'block' }}>
                
              {listing.map((listing, index) => (
                <div className="owl-item" style={{ width: '201px' }}>
                  <div className="item">
                  <a href={`/profile?p=${encodeURIComponent(listing.public_key)}`}>
                      <span className="item-carousel-thumb">
                        <img className="img-responsive" src={listing.img_listing} alt="img" />
                      </span>
                      <span className="item-name">{listing.name}</span>
                      <span className="price">{listing.subcat_name}</span>
                    </a>
                  </div>
                </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 
  )
}

export default TopList