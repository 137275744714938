import React from "react";

import { Button } from 'primereact/button';

const ViewListTemplate = ({ handleAddButtonClick,AddButtonName, showEditor, children }) => {
  return (
  <>



<div id="frmlist" className={showEditor && 'hidden'}>
    <div class="btn-toolbar">
        <div class="btn-group focus-btn-group">
        
            <Button label={AddButtonName} type="button" severity="info" onClick={() => handleAddButtonClick("create")} icon="pi pi-plus" />
        </div>
        <div class="btn-group dropdown-btn-group pull-right">
        </div>
    </div>
    <div>

    {children}

      </div>

    </div>


  </>
  );
};

export default ViewListTemplate;