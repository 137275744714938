import React, { useState, useEffect,useRef  } from "react";
import ViewTemplate from "../../../components/shared/ViewTemplate";
import config from "../../../config";
import OrgsystemList from "./orgsystemList";
import OrgsystemEditor from "./orgsystemEditor";

const Orgsystem = () => {
  const editRef = useRef();

    const breadcrumList = {
      view: "Organization Systems Lists",
      menu: "Catalogs",
      mode: "Show systems",
      module: "Organization Systems",
    };

    const breadcrumEditor = {
      view: "Edit System",
      menu: "Catalogs",
      mode: "System Editor",
      module: "Organization Systems",
    };

    const breadcrumCreate = {
      view: "Create New System",
      menu: "Catalogs",
      mode: "System Editor",
      module: "Organization Systems",
    };

    const [breadcrum, setBreadcrum] = useState(breadcrumList);
    const [showEditor, setShowEditor] = useState(false);
  

    const handleAddButtonClick = (mode) => {
      setShowEditor(true);    
     // editRef.current && editRef.current.hideSpinner();
      editRef.current && editRef.current.EditorNew();
      EditorMode("create");
    };

    
    const HandleEditRow = (rowData) => {
      editRef.current && editRef.current.GetEntity(rowData.public_key);
      setShowEditor(true);  
      EditorMode("edit"); 
    };

    
  const handleBackButtonClick = () => {
    editRef.current && editRef.current.EditorNew();
    setShowEditor(false);
    ListMode();
  };

  const EditorMode = (mode) => {
    if (mode === "edit") {
      setBreadcrum(breadcrumEditor);
    } else if (mode === "create") {
      setBreadcrum(breadcrumCreate);
    }
  };

  const ListMode = () => {
    setBreadcrum(breadcrumList);
  };  

  return (

        <ViewTemplate
          breadcrum={breadcrum}          
          handleAddButtonClick={handleAddButtonClick}
          handleBackButtonClick={handleBackButtonClick}
        >

        <OrgsystemEditor
        breadcrumCreate={breadcrumCreate}
        setBreadcrum={setBreadcrum}
        breadcrumEditor={breadcrumEditor}
          setShowEditor={setShowEditor}
          ref={editRef}
          /* classnameHidden ={classnameHidden} */
          showEditor = {showEditor}
          handleBackButtonClick={handleBackButtonClick}
        />

      

        <OrgsystemList
        HandleEditRow={HandleEditRow} 
        showEditor = {showEditor}
        handleAddButtonClick={handleAddButtonClick}
        />


    </ViewTemplate>
  );
};

export default Orgsystem;
