import React from 'react'
import { useState } from "react";

import Mainmenu from '../../components/mainmenu/mainmenu'
import Navbar from '../../components/navbar/navbar'
import SearchMain from '../../components/search/searchmain'
import CategoryList from '../../components/listing/categorylist'
import TopList from '../../components/listing/toplist'
import Breadcrumb from '../../components/shared/BreadCrum'
import Sidebar from "../../components/mainmenu/sidebar";

const Dashboard = () => {

  const [breadcrum, setBreadcrum] = useState({
    view : "Dashboard",
    menu: "Catalogs",
    mode : "",
    module : "Dashboard"
  });

  return (
    <div id="wrapper2">

        <Navbar />
        <SearchMain />
        
        <div className="main-container">
        <div className="container">

        <CategoryList />
        <TopList />

        </div>
        </div>
    </div> 
  )
}

export default Dashboard