import React, { useEffect,useState } from "react";
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Search from '../shared/search';
import { useAuth } from "../../components/AuthHooks/AuthProvider.jsx";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
    // Custom hook to access user session context
    const navigate = useNavigate();

    const auth = useAuth();
    if (auth.user==null){
        //navigate("/login");
    }

    useEffect(() => {

        var menuToggleBtn = document.querySelector('.button-menu-mobile');

        function handleMenuToggleClick() {

            var configSize = ''; //document.documentElement.config.sidebar.size;
            var size = document.documentElement.getAttribute('data-sidebar-size');

            document.documentElement.setAttribute('data-sidebar-size', size === 'condensed' ? 'default' : 'condensed');

            document.documentElement.classList.toggle('sidebar-enable');
        }

        if (menuToggleBtn) {
            menuToggleBtn.addEventListener('click', handleMenuToggleClick);
        }

        // Cleanup function to remove event listener when component unmounts
        return () => {
            if (menuToggleBtn) {
               menuToggleBtn.removeEventListener('click',handleMenuToggleClick);
            }
        };
    }, []); // Empty dependency array ensures that this effect runs only once after the component mounts



    return (
    <>

<div class="header">
        <nav class="navbar  fixed-top navbar-site navbar-light bg-light navbar-expand-md"
             role="navigation">
            <div class="container">

                <div class="navbar-identity">


                    <a href="index.html" className="navbar-brand logo logo-title">
                        <img src="/assets/images/logos/logo.png" alt="Dirdig Directio Digital" className="logo-image" />
                    </a>
{/* 
                    <button class="navbar-toggler -toggler pull-right" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarsDefault" aria-controls="navbarsDefault" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 30 30" width="30" height="30"
                             focusable="false"><title>Menu</title>
                            <path stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10"
                                  d="M4 7h22M4 15h22M4 23h22"/>
                        </svg>
                    </button>


                    <button
                            class="flag-menu country-flag d-block d-md-none btn btn-secondary hidden pull-right"
                            data-bs-target="#select-country" data-bs-toggle="modal"><span
                            class="flag-icon flag-icon-us"></span> <span class="caret"></span>
                    </button> */}

                </div>


                {/* <div class="navbar-collapse collapse" id="navbarsDefault">
                    <ul class="nav navbar-nav me-md-auto navbar-left">
                        <li class="flag-menu country-flag hidden-xs nav-item" title="Select Country">
                            <a data-bs-target="#select-country" data-bs-toggle="modal" class="nav-link">
                            <span class="flag-icon flag-icon-us"></span> <span class="caret"></span>
                        </a>
                        </li>
                    </ul>
                    <ul class="nav navbar-nav ml-auto navbar-right">
                        <li class="nav-item"><a href="category.html" class="nav-link"><i class="icon-th-thumb"></i> All Ads</a>
                        </li>
                        <li class="dropdown no-arrow nav-item"><a href="#" class="nav-link dropdown-toggle" linkto="#"
                                                                  id="dropdown-nav-01" data-bs-toggle="dropdown"
                                                                  aria-expanded="false">

                            <span>Jhon Doe</span> <i class="icon-user fa"></i> <i class=" icon-down-open-big fa"></i></a>
                            <ul
                                    class="dropdown-menu user-menu dropdown-menu-right" aria-labelledby="dropdown01">
                                <li class="active dropdown-item"><a href="account-home.html"><i class="icon-home"></i>
                                    Personal Home

                                </a>
                                </li>
                                <li class="dropdown-item"><a href="account-myads.html"><i class="icon-th-thumb"></i> My ads
                                </a>
                                </li>
                                <li class="dropdown-item"><a href="account-favourite-ads.html"><i class="icon-heart"></i>
                                    Favourite ads </a>
                                </li>
                                <li class="dropdown-item"><a href="account-saved-search.html"><i
                                        class="icon-star-circled"></i> Saved search

                                </a>
                                </li>
                                <li class="dropdown-item"><a href="account-archived-ads.html"><i
                                        class="icon-folder-close"></i> Archived ads

                                </a>
                                </li>
                                <li class="dropdown-item"><a href="account-pending-approval-ads.html"><i
                                        class="icon-hourglass"></i> Pending

                                    approval </a>
                                </li>
                                <li class="dropdown-item"><a href="statements.html"><i class=" icon-money "></i> Payment
                                    history </a>
                                </li>
                                <li class="dropdown-item"><a href="login.html"><i class=" icon-logout "></i> Log out </a>
                                </li>
                            </ul>
                        </li>
                        <li class="postadd nav-item"><a class="btn btn-block   btn-border btn-post btn-danger nav-link"
                                                        href="post-ads.html">Post Free Add</a>
                        </li>
                        <li class="dropdown  lang-menu nav-item">
                            <a href="#" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown"
                               aria-expanded="false" id="dropdown-nav-02">
                                <span class="lang-title">EN</span>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-right user-menu" role="menu"
                                aria-labelledby="dropdown-nav-02">
                                <li class="dropdown-item"><a class="active">

                                    <span class="lang-name">English</span></a>
                                </li>
                                <li class="dropdown-item"><a><span class="lang-name">Dutch</span></a>
                                </li>
                                <li class="dropdown-item"><a><span class="lang-name">fran&#xE7;ais </span></a>
                                </li>
                                <li class="dropdown-item"><a><span class="lang-name">Deutsch</span></a>
                                </li>
                                <li class="dropdown-item"><a> <span class="lang-name">Arabic</span></a>
                                </li>
                                <li class="dropdown-item"><a><span class="lang-name">Spanish</span></a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div> */}
       
            </div>
        
        </nav>
    </div>

    </>
  )
}

export default Navbar