import React from 'react'

const searchmain = () => {
  return (
    <div className="intro" style={{backgroundImage: 'url(assets/images/bg2.jpg)'}}>
    <div className="container text-center">

        <h1 className="intro-title animated fadeInDown">
            Find Classified Ads
        </h1>

        <p className="sub animateme fittext3 animated fadeIn">
            Find local classified ads on bootclassified in Minutes
        </p>

        <div className="row search-row animated fadeInUp">
            <div className="col-xl-4 col-sm-4 search-col relative locationicon">
                <div className="search-col-inner">
                    <i className="icon-location-2 icon-append"></i>
                    <div className="search-col-input">
                        <input className="form-control locinput input-rel searchtag-input has-icon"
                            id="autocomplete-ajax" name="country"
                            placeholder="City/Zipcode..."
                            type="text" value="" />
                    </div>
                </div>
            </div>

            <div className="col-xl-4 col-sm-4 search-col relative">
                <div className="search-col-inner">

                    <i className="icon-docs icon-append"></i>
                    <div className="search-col-input">
                        <input className="form-control has-icon" name="ads" placeholder="I'm looking for a ..."
                            type="text" value="" />
                    </div>
                </div>
            </div>
            <div className="col-xl-4 col-sm-4 search-col">
                <button className="btn btn-primary btn-search btn-block  btn-gradient"><i
                        className="icon-search"></i><strong>Find</strong></button>
            </div>
        </div>

    </div>
</div>
  )
}

export default searchmain