import React, { useState, forwardRef, useEffect,useRef } from 'react';
import Grid from '../../../components/neulib/Grid';
import config from '../../../config';
import { Button } from 'primereact/button';
import ViewListTemplate  from '../../../components/shared/ViewListTemplate'
import DataSource from '../../../components/stores/DataSource';
import { useAuth } from "../../../components/AuthHooks/AuthProvider.jsx";
import StoreSystem  from '../../../components/stores/StoreSystem';
import { Messages } from 'primereact/messages';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import Moment from 'react-moment';
import moment from 'moment';

const OrgsystemList = ({handleAddButtonClick,HandleEditRow,showEditor}) => {
  const msgs = useRef(null);
  const toast = useRef(null);
  const listRef = useRef();

  // Define columns configuration
  const columns = [
    { field: 'public_key', header: 'ID',sortable:true, colwidth:'15%' , searchable:true },
    { field: 'name', header: 'Name' ,sortable:true, colwidth:'15%' ,searchable:true },
    { field: 'description', header: 'Description' ,sortable:true, colwidth:'50%',searchable:true },
    { field: 'updated_at', header: 'Last Update' ,sortable:true, colwidth:'50%',searchable:true ,body: (rowData) => dynamicFunctionDate('dateFormat', rowData)},
    { field: 'updated_by_name', header: 'Updated by' ,sortable:true, colwidth:'50%',searchable:true },
    {  header: 'Action', sortable:false, colwidth:'10%',body: (rowData) => dynamicFunction('actionBodyTemplate', rowData)}
    
    // Add more columns if needed
  ];

  const [rowdata, setRowData] = useState(null);

  const auth = useAuth();
  // Define other props
  const rowsPerPage = 50;
  const fetchUrl = config.PagerfetchUrl; //'negotiate/pager';
  const Rid = 'system_grid';
  const appid = 'riskwire';
  const org = 'ew';
  const size = 'small';

  const dateFormat = (gridrowData) => {
    //return <span>{rowData.name}</span>;
    return <>
 <Button onClick={() => HandleEditRow(gridrowData)} icon="pi pi-pen-to-square" style={{ fontSize: '1rem' }}  aria-label="Filter" />
 <Button onClick={() => confirmDelete(gridrowData)} icon="pi pi-trash" severity='danger' style={{ fontSize: '1rem' }}  aria-label="Filter" />
 </>
  };

  const actionBodyTemplate = (gridrowData) => {
    //return <span>{rowData.name}</span>;
    return <>
 <Button onClick={() => HandleEditRow(gridrowData)} icon="pi pi-pen-to-square" style={{ fontSize: '1rem' }}  aria-label="Filter" />
 <Button onClick={() => confirmDelete(gridrowData)} icon="pi pi-trash" severity='danger' style={{ fontSize: '1rem' }}  aria-label="Filter" />
 </>
  };

  const functionMappings = {
    actionBodyTemplate
    // Add more function mappings if need
  };

  const functionMappingsDate = {
    actionBodyTemplate
    // Add more function mappings if need
  };

  const dynamicFunction = (name, rowData) => {
    return functionMappings[name](rowData);
  };

  const dynamicFunctionDate = (name, rowData) => {
    const start = moment(rowData.updated_at);
    if (start.isValid()) {
      // Return formatted date using Moment component
      return <Moment date={start} format="YYYY-MM-DD hh:mm A" />;
    } else {
      // Return a string if the date is invalid
      return "N/A";
    }
  };

  const HandleRemoveRow = (rowdata) => {
   StoreSystem.Disable(
    rowdata,
     (data) => {
      listRef.current && listRef.current.fetchData();
       if (msgs.current) {
           msgs.current.clear();
           msgs.current.show({sticky: true, severity: 'success', summary: 'Success', detail: 'System successful disabled', closable: false});
           accept(); 
        }
       //setRowData(null);
     },
     (error) => {
       console.error("Error fetching data:", error);
       let errors = [error];
     },
     auth.token
   );
  };

  const accept = () => {
    toast.current.show({ severity: 'info', summary: 'Confirmed', detail: 'System successful disabled', life: 3000 });
}

const reject = () => {
    toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected the action', life: 3000 });
};

  const confirmDelete = (griddata) => {
    setRowData(griddata);

    confirmDialog({
      message: 'Do you want to disable this system?',
      header: 'Disable Confirmation',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => HandleRemoveRow(griddata),
      reject
    });
  };

  return (
    <ViewListTemplate  
      handleAddButtonClick={handleAddButtonClick}
      AddButtonName="Create new System"
      showEditor = {showEditor}
    >

<Toast ref={toast} />
            <ConfirmDialog  />
  <Messages ref={msgs} /> 

  <Grid
    columns={columns}
    Datasource={DataSource.NetCorePrd}
    dynamicFunction={dynamicFunction}
    rowsPerPage={rowsPerPage}
    fetchUrl={fetchUrl}
    Rid={Rid}
    ref={listRef}
    appid={appid}
    org={org}
    size={size}          
    tableStyle={{ minWidth: '50rem' }}
  />
        
    </ViewListTemplate>
      

  );
};

export default OrgsystemList;
