import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from '../../config';
import DataSource from '../stores/DataSource';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  let parsedUser = null;
  
  if (typeof parsedUser === 'object') {
    parsedUser = JSON.parse(localStorage.getItem("user"));  
  } 

  const [user, setUser] = useState(parsedUser);
  const [token, setToken] = useState(localStorage.getItem("site") || "");
  const navigate = useNavigate();

  const GetToken = () => {
    return localStorage.getItem("site");
  }

  const url = `${DataSource.NetCorePrd}/${config.Auth}`;
  
  const loginAction = async (data) => {
    try {

      let requestdata = {
        Rid : "login",
        appid : "security",
        org : "ew",
        RequestData : JSON.stringify(data)
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestdata),
      });
      const res = await response.json();
      console.log(res);
      if (res.data) {
        if (res.data.ck_value){
          setUser(res.data);
          setToken(res.data.ck_value);
          localStorage.setItem("site", res.data.ck_value);
          localStorage.setItem("user", JSON.stringify(res.data));
          navigate("/dashboard");
          return true;
        }
      }
      return false;
      //throw new Error(res.message);
    } catch (err) {
      //console.error(err);
      return false;
    }
  };

  const logOut = () => {
    setUser(null);
    setToken(null);
    localStorage.removeItem("site");
    localStorage.removeItem("user");
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};