import React, { Children } from 'react'
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';

const ViewEditTemplate = ({handleSaveButtonClick,handleBackButtonClick,showEditor,classnameHidden,children}) => {
  return (

<div className={!showEditor && "hidden"}>

{classnameHidden=='hidden' && (
    <div className="card">
                  <ProgressSpinner aria-label="Loading" style={{width: '100px', height: '100px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
        </div>
)
}

<div className={classnameHidden}> 


<div className="btn-toolbar">
    <div className="btn-group focus-btn-group">
    </div>
    <div className="btn-group pull-right" style={{float:'right'}}>
 
        
        <Button label="Back" type="button"  severity="secondary" onClick={handleBackButtonClick} icon="pi pi-home" size="small"/>
        <Button label="Save" type="button" severity="success" icon="pi pi-save" onClick={handleSaveButtonClick} size="small"/>

    </div>
</div>

<br />

<div id="editor-info" className="row">


    <div className="col-lg-12">
              
    {children}
    
    </div>

</div>

</div>
</div>
  )
}

export default ViewEditTemplate